.MenuButton
{
    color: rgb(68, 68, 68);
    text-align:'center';
    font-size: 24px;
    margin-right: 50px
}

.ImageHero {
    width: 320px;
    box-shadow: 5px 5px 5px darkgrey;
}

.DesktopOnly {
    display: block;
}

.MobileOnly {
    display: none;
}

.FontSizeLevel0 {
    font-size: 10px;
}

.FontSizeLevel1 {
    font-size: 14px;
}

.FontSizeLevel2 {
    font-size: 24px;
}

.FontSizeLevel3 {
    font-size: 30px;
}

.FontSizeLevel4 {
    font-size: 36px;
}

.FontSizeLevel5 {
    font-size: 60px;
}

.FontSizeLevel6 {
    font-size: 70px;
}

.FontSizeLevel7 {
    font-size: 75px;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
}

.image-gallery-thumbnail img {
    height: 100px;
}


@media only screen and (max-width: 768px) {
    .MenuButton {
        color: rgb(68, 68, 68);
        text-align:'center';
        font-size: 24px;
        margin-right: 15px;
        margin-left: 30px
    }

    .ImageHero {
        width: 280px;
        margin-top: -25px;
        box-shadow: 5px 5px 5px darkgrey;
    }

    .DesktopOnly {
        display: none;
    }
    
    .MobileOnly {
        display: block;
    }

    .FontSizeLevel0 {
        font-size: 10px;
    }
    
    .FontSizeLevel1 {
        font-size: 12px;
    }

    .FontSizeLevel2 {
        font-size: 18px;
    }

    .FontSizeLevel3 {
        font-size: 24px;
    }

    .FontSizeLevel4 {
        font-size: 30px;
    }

    .FontSizeLevel5 {
        font-size: 36px;
    }

    .FontSizeLevel6 {
        font-size: 40px;
    }

    .FontSizeLevel7 {
        font-size: 50px;
    }



}

.BodyText
{
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight:900;
    -webkit-font-smoothing: subpixel-antialiased;
}

.flexContainer {
    display: flex; /* or inline-flex */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.flexContainerLeft {
    display: flex; /* or inline-flex */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}

#root {
    background:  url('../src/content/background.png') center left;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    /* min-height:100%; */
    /* height: 100%; */
    margin: 0
}

/* .image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
} */

/* .image-gallery-slide {
    width: auto;
    height: 600px;
} */



/* .image-gallery-thumbnail {
    width: 100%;
    height: 100px;
} */



.ThemeFont {
    font-family: 'Playfair Display', serif;
}

.ThemeFont2
{    
    font-family: 'IM Fell English', serif;
    -webkit-font-smoothing: subpixel-antialiased;
}

.SignatureFont
{
    font-family: 'Herr Von Muellerhoff', cursive;
}

.footer {
    background-color: rgba(0,0,0,0.85);
    position: fixed;
    left: 0px;
    right: 0;
    bottom: 0;
    height: 60px;
    -webkit-font-smoothing: subpixel-antialiased;
}

.RoundProfilePic
{
    height:100px;
    border-radius:50px;
    margin-left: 15px;
    margin-right: 30px;
}



.FlexContainer {
    display: flex; /* or inline-flex */
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.AccentColor {
    color: rgb(51, 74, 116)
}

.AccentBackgroundColor {
    background-color: rgb(4, 61, 167)
}






.SubBodyText
{
    font-family: 'Roboto', sans-serif;
    color: darkgray;
    font-weight:600;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;

    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    text-overflow: ellipsis;
    -webkit-font-smoothing: subpixel-antialiased;
}